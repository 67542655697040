import React from "react"

import moment from "moment"

import { isEmpty } from "lodash"

import { getPaginationDefault } from "api/hooks"

import { valueToCurrency } from "constants/index"

import { getColumnSort } from "Components/TableNew/helpers"
import { TableHeader } from "Components/TableNew"
import SearchFilter from "Components/TableNew/TableFilters/SearchFilter"
import SelectFilter from "Components/TableNew/TableFilters/SelectFilter"
import { FromToFilter } from "Components/TableNew/TableFilters/FromToFilter"
import FromToDates from "Components/TableNew/TableFilters/FromToDates"
import DateFilter from "Components/TableNew/TableFilters/DateFilter"
import TooltipComponent from "Components/Tooltip"

import { FromToFilterSetterProps } from "Components/TableNew/TableFilters/FromToFilter/types"
import { FilesModalState } from "./Components/types"

import { ReactComponent as AttachIcon } from "Components/icons/attach_20.svg"
import { ReactComponent as StatusCommentIcon } from "Components/icons/info.svg"
import { ReactComponent as RubleIcon } from "Components/icons/money_circle_outline.svg"
import { ReactComponent as EditIcon } from "Components/icons/crud/edit_20.svg"

import {
  FilterState,
  FilterTable,
  PayrollFile,
  PayrollTableFields,
  PayrollTableState,
  PayrollTableStatus
} from "./types"

import { PayrollSetModalProp } from "../types"

export const initialState: PayrollTableState = {
  data: [],
  pending: false,
  total_count: 0
}

export const initialFilter: FilterState = {
  table: {
    document_number: undefined,
    hall: undefined,
    sum: undefined,
    billing_period: undefined,
    preparation_date: undefined,
    status: undefined,
    sort: {}
  },
  pagination: getPaginationDefault(30)
}
interface ColumnsArg extends PayrollSetModalProp {
  filters: FilterState
  setFilter: (val: FilterState) => void
  changeFilesModal: (val: FilesModalState) => void
}

export const prepareTableFilter = (filters: FilterTable) => {
  const sort = filters.sort?.field ? filters.sort : undefined

  function areAllPropsUndefined(obj: any) {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return false
      }
    }
    return true
  }
  const filtersWithSort = { ...filters, sort: undefined }
  const isEmptyFilter =
    areAllPropsUndefined(filtersWithSort) || isEmpty(filtersWithSort)
  const filter = isEmptyFilter ? undefined : filtersWithSort

  return { filter, sort }
}

export const statusText = {
  [PayrollTableStatus.success]: `Успешно`,
  [PayrollTableStatus.delivered]: `Доставлено`,
  [PayrollTableStatus.denied]: `Отказано`,
  [PayrollTableStatus.failed]: `Ошибка`,
  [PayrollTableStatus.new]: `Новая`,
  [PayrollTableStatus.pending]: `В отправке`
}

const statusOptions = [
  {
    value: PayrollTableStatus.new,
    label: statusText[PayrollTableStatus.new]
  },
  {
    value: PayrollTableStatus.success,
    label: statusText[PayrollTableStatus.success]
  },
  {
    value: PayrollTableStatus.delivered,
    label: statusText[PayrollTableStatus.delivered]
  },
  {
    value: PayrollTableStatus.pending,
    label: statusText[PayrollTableStatus.pending]
  },
  {
    value: PayrollTableStatus.denied,
    label: statusText[PayrollTableStatus.denied]
  },
  {
    value: PayrollTableStatus.failed,
    label: statusText[PayrollTableStatus.failed]
  }
]

export const textByField = {
  main: {
    filterLabel: `Фильтровать`
  },
  [PayrollTableFields.document_number]: {
    title: `№ \nдокумента`,
    label: `№ документа`,
    placeholder: `Введите № документа`
  },
  [PayrollTableFields.hall]: {
    title: `Клуб`,
    label: `Клуб`,
    placeholder: `Выберите клуб`
  },
  [PayrollTableFields.sum]: {
    title: `Сумма, ₽`,
    label: `Сумма, ₽`,
    placeholder: ``
  },
  [PayrollTableFields.billing_period]: {
    title: `Расчётный \nпериод`,
    label: `Расчётный период`
  },
  [PayrollTableFields.preparation_date]: {
    title: `Дата \nсоставления`,
    label: `Дата составления`
  },
  [PayrollTableFields.files]: {
    title: `Файлы`,
    empty: `-`
  },
  [PayrollTableFields.created_date]: {
    title: `Дата \nсоздания (мск)`
  },
  [PayrollTableFields.status]: {
    title: `Статус`,
    label: `Статус`,
    placeholder: `Выберите статус`
  }
}

export const getColumns = ({
  filters,
  setFilter,
  changeFilesModal,
  setPayrollModal
}: ColumnsArg) => {
  return [
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.document_number
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PayrollTableFields.document_number] ||
              filters.table.sort?.field === PayrollTableFields.document_number
            }
            className="MarginLeft16"
            title={textByField[PayrollTableFields.document_number].title}
            modalTitle={textByField[PayrollTableFields.document_number].label}
          >
            <SearchFilter
              value={filters.table[PayrollTableFields.document_number]}
              withEqual={false}
              withSort={true}
              iconSearch={true}
              label={textByField.main.filterLabel}
              currentSort={filters.table.sort}
              field={PayrollTableFields.document_number}
              placeholder={
                textByField[PayrollTableFields.document_number].placeholder
              }
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PayrollTableFields.document_number]:
                      newValue?.value || undefined,
                    sort: {
                      order: newValue?.order,
                      field: newValue?.sortField
                    }
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 110,
      dataIndex: PayrollTableFields.document_number,
      key: PayrollTableFields.document_number,
      render: (document_number: string) => (
        <div className="MarginLeft16">{document_number}</div>
      )
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.hall
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PayrollTableFields.hall] ||
              filters.table.sort?.field === PayrollTableFields.hall
            }
            className=""
            title={textByField[PayrollTableFields.hall].label}
          >
            <SelectFilter
              value={filters.table[PayrollTableFields.hall]}
              isHall
              withSort
              showSearch
              label={textByField.main.filterLabel}
              placeholder={textByField[PayrollTableFields.hall].placeholder}
              currentSort={filters.table.sort}
              currentField={PayrollTableFields.hall}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PayrollTableFields.hall]: newValue.value,
                    sort: newValue.sort
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 80,
      dataIndex: PayrollTableFields.hall,
      key: PayrollTableFields.hall,
      render: (hall: string) => <div>{hall}</div>
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.sum
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isActiveField={!!filters.table[PayrollTableFields.sum]}
            isSort={isSort}
            title={textByField[PayrollTableFields.sum].title}
          >
            <FromToFilter
              suffix={<RubleIcon />}
              isFloatNumber={false}
              label={textByField.main.filterLabel}
              fieldName={PayrollTableFields.sum}
              title={textByField[PayrollTableFields.sum].label}
              from={filters.table[PayrollTableFields.sum]?.from}
              to={filters.table[PayrollTableFields.sum]?.to}
              currentSort={filters.table.sort}
              defaultFilter={{
                field: PayrollTableFields.sum,
                from: filters.table[PayrollTableFields.sum]?.from,
                to: filters.table[PayrollTableFields.sum]?.to
              }}
              currentFilter={{
                ...filters.table[PayrollTableFields.sum],
                field: PayrollTableFields.sum
              }}
              setter={(fromToState: FromToFilterSetterProps) => {
                const newSort = {
                  field: fromToState.sortField,
                  order: fromToState.order
                }
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    sort: newSort,
                    [PayrollTableFields.sum]:
                      fromToState.from ||
                      fromToState.to ||
                      fromToState.from === 0 ||
                      fromToState.to === 0
                        ? {
                            from: fromToState.from,
                            to: fromToState.to
                          }
                        : undefined
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PayrollTableFields.sum,
      key: PayrollTableFields.sum,
      render: (sum: string) => {
        const currency = valueToCurrency({
          value: Number(sum),
          withoutSpace: true
        })
        return <div>{currency}</div>
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.hall
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PayrollTableFields.billing_period] ||
              filters.table.sort?.field === PayrollTableFields.billing_period
            }
            className=""
            title={textByField[PayrollTableFields.billing_period].title}
            modalTitle={textByField[PayrollTableFields.billing_period].label}
          >
            <DateFilter
              value={filters.table[PayrollTableFields.billing_period]}
              currentSortedField={filters.table.sort?.field}
              currentSortedOrder={
                filters.table.sort?.field === PayrollTableFields.billing_period
                  ? filters.table.sort?.order
                  : undefined
              }
              label={textByField.main.filterLabel}
              currentField={PayrollTableFields.billing_period}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PayrollTableFields.billing_period]: newValue.value,
                    sort: newValue.sort
                  }
                })
              }
              picker="month"
              format="MMM YYYY"
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PayrollTableFields.billing_period,
      key: PayrollTableFields.billing_period,
      render: (billing_period: string) => {
        const dateByFormat = moment(
          billing_period,
          `YYYY-MM-DDTHH:mm:ss`
        ).format(`MMM YYYY`)
        return <div className="CapitalizeText">{dateByFormat}</div>
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.hall
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        const fromVal = filters.table[PayrollTableFields.preparation_date]?.from
        const toVal = filters.table[PayrollTableFields.preparation_date]?.to
        const fromDate = !!fromVal ? moment(fromVal) : undefined
        const toDate = !!toVal ? moment(toVal) : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PayrollTableFields.preparation_date] ||
              filters.table.sort?.field === PayrollTableFields.preparation_date
            }
            className=""
            title={textByField[PayrollTableFields.preparation_date].title}
            modalTitle={textByField[PayrollTableFields.preparation_date].label}
          >
            <FromToDates
              rangeOnly
              hasSort={
                filters.table.sort.field === PayrollTableFields.preparation_date
              }
              order={filters.table.sort.order}
              fromDate={fromDate}
              toDate={toDate}
              changeFilter={(newFilters: any) => {
                const falseSort =
                  filters.table.sort.field ===
                  PayrollTableFields.preparation_date
                    ? {}
                    : filters.table.sort
                setFilter({
                  table: {
                    ...filters.table,
                    [PayrollTableFields.preparation_date]:
                      newFilters.from || newFilters.to
                        ? {
                            from: moment(newFilters.from).utc(true),
                            to: moment(newFilters.to).utc(true)
                          }
                        : undefined,
                    sort: newFilters.hasSort
                      ? {
                          order: newFilters.order,
                          field: PayrollTableFields.preparation_date
                        }
                      : falseSort
                  },
                  pagination: getPaginationDefault(filters.pagination.limit)
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PayrollTableFields.preparation_date,
      key: PayrollTableFields.preparation_date,
      render: (preparation_date: string) => {
        const dateByFormat = moment(
          preparation_date,
          `YYYY-MM-DDTHH:mm:ss`
        ).format(`DD.MM.YYYY`)
        return <div>{dateByFormat}</div>
      }
    },
    {
      title: textByField[PayrollTableFields.files].title,
      width: 80,
      dataIndex: PayrollTableFields.files,
      key: PayrollTableFields.files,
      render: (files: PayrollFile[]) => {
        const filesCount = files.length
        return files.length ? (
          <div
            className="FlexRow Gap8 PayrollTableFiles"
            onClick={() => changeFilesModal({ open: true, data: files })}
          >
            <AttachIcon />
            <span className="TextOne Gray600Color PaddingTop2">
              {filesCount}
            </span>
          </div>
        ) : (
          <div className="CaptionText">
            {textByField[PayrollTableFields.files].empty}
          </div>
        )
      }
    },
    {
      title: textByField[PayrollTableFields.created_date].title,
      width: 136,
      dataIndex: PayrollTableFields.created_date,
      key: PayrollTableFields.created_date,
      render: (created_date: string) => {
        const dateByFormat = moment(created_date, `YYYY-MM-DDTHH:mm:ss`)
        const date = dateByFormat.format(`DD.MM.YYYY`)
        const time = dateByFormat.format(`HH:mm`)
        return (
          <div className="FlexColumn Gap4">
            <span className="TextOne">{date}</span>
            <span className="TextOne Gray600Color">{time}</span>
          </div>
        )
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PayrollTableFields.status
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={!!filters.table[PayrollTableFields.status]}
            className=""
            title={textByField[PayrollTableFields.status].label}
          >
            <SelectFilter
              value={filters.table[PayrollTableFields.status]}
              placeholder={textByField[PayrollTableFields.status].placeholder}
              withSort={false}
              options={statusOptions}
              currentSort={filters.table.sort}
              currentField={PayrollTableFields.status}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PayrollTableFields.status]: newValue.value,
                    sort: newValue.sort
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 176,
      dataIndex: PayrollTableFields.status,
      key: PayrollTableFields.status,
      render: (
        status: PayrollTableStatus,
        record: { status_reason: string }
      ) => (
        <div
          className={`PayrollStatusTableColumn PayrollStatus-${status} FlexRow`}
        >
          <span className="StatusText">{statusText[status]}</span>

          <div className="MarginLeftAuto">
            <TooltipComponent title={record.status_reason} direction="top">
              <div className="PayrollStatusTableComment">
                <StatusCommentIcon />
              </div>
            </TooltipComponent>
          </div>
        </div>
      )
    },
    {
      title: "",
      width: 56,
      dataIndex: `id`,
      key: `id`,
      render: (id: number, record: any) => {
        return (
          <div className="PayrollTableEditItem">
            {record.status !== PayrollTableStatus.success && (
              <div
                onClick={() =>
                  setPayrollModal({ open: true, editData: record })
                }
              >
                <EditIcon />
              </div>
            )}
          </div>
        )
      }
    }
  ]
}
